.App-MarkdownPreviewer {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  /* height: 100%; */
  background-color: #5ebfbf;
}

.header-MarkdownPreviewer {
  background-color: #000000;
  font-family: "Source Code Pro", monospace;
  color: #00ff00;
}

.header-MarkdownPreviewer > h1 {
  padding: 1rem;
  padding-left: 1.5rem;
}

.editor {
  margin: 0;
  margin-top: 5vh;
}

textarea {
  background-color: #000000;
  color: #00ff00;
  padding: 1rem;
  font-size: larger;
  margin-bottom: 3vh;
}

.previewer {
  background-color: #efffff;
  font-family: "Poppins", sans-serif;
  color: #000000;
  width: 50vw;
  min-width: 512px;
  padding: 0.5rem 2rem;
  border: 0.25px solid #000000;
  margin-bottom: 5vh;
}

code {
  background-color: white;
  padding: 0.2rem;
}

pre {
  background-color: white;
  padding: 1rem;
  border: 0.25px solid #000000;
}

table {
  border-collapse: collapse;
}

td,
th {
  border: 1px solid #000000;
  padding: 0.3rem;
}
