.App-JavascriptCalculator {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  height: 100%;
  font-family: "Kanit", sans-serif;
}

.calculator-container {
  margin-top: 70px;
  height: 420px;
  width: 300px;
  padding: 2px;
  background-color: #b9b9b9;
  display: inline-grid;
  border: 5px solid #b9b9b9;
  border-radius: 15px;
  box-shadow: 2.5px 2.5px 3px 3px #7a7a7a;
  grid-column: 7vw 7vw 7vw 7vw;
  grid-row: 70px 70px 70px 70px 70px 70px;
}

.screen {
  background-color: black;
  grid-column: 1 / span 4;
  border-radius: 10px 10px 0 0;
  font-size: xx-large;
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 2px;
  overflow: hidden;
  font-family: "Roboto Mono", monospace;
}

.screen > div {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
}

.screen > div > p {
  text-align: end;
  margin-top: -15px;
  margin-right: 10px;
}

.total {
  margin-bottom: 0;
  padding-bottom: 5px;
}

.all-clear {
  grid-column: 1 / span 2;
}

.zero {
  grid-column: 1 / span 2;
}

.equals {
  grid-column: 4 / span 1;
  grid-row: 5 / span 2;
}

.box {
  background-color: #ffffff;
  margin: 2px;
  font-size: xx-large;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0.75px 0.75px 0.75px 0.75px #7a7a7a;
}

.box:hover {
  background-color: #b5ffff;
}
.box:active {
  background-color: #00ff00;
}

.btm-right {
  border-radius: 0 0 10px 0;
}

.btm-left {
  border-radius: 0 0 0 10px;
}

.r1 {
  grid-row: 1 / span 1;
}
.r2 {
  grid-row: 2 / span 1;
}
.r3 {
  grid-row: 3 / span 1;
}
.r4 {
  grid-row: 4 / span 1;
}
.r5 {
  grid-row: 5 / span 1;
}
.r6 {
  grid-row: 6 / span 1;
}

footer {
  text-align: center;
  position: absolute;
  bottom: 10px;
}

footer > p,
footer > p > a {
  margin: 0;
}
