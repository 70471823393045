.App-timer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Roboto Mono", monospace;
  background-color: #000000;
  min-height: 100vh;
}

.border-timer {
  border-radius: 20px;
}

.stopwatch-timer {
  background-color: #000000;
  width: 420px;
  display: flex;
  border-radius: 100px;
  flex-direction: column;
  align-items: center;
  padding: 40px 100px 40px 100px;
  margin-top: 50px;
}

.white-timer {
  display: flex;
  align-items: baseline;
}

.adjustment-container-timer {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  background-color: transparent;
}

.adjustment-container-timer > h1 {
}

.flex-timer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.break-length-container-timer {
  background-color: #fffecc;

  padding-top: 20px;
  padding-bottom: 10px;
  width: 250px;
  margin-right: 15px;
}

.session-length-container-timer {
  background-color: #fffecc;

  padding-top: 20px;
  padding-bottom: 10px;
  width: 250px;
  margin-left: 15px;
}

.timer-container-timer {
  background-color: #fffecc;
  width: 280px;
  padding: 20px 10px 20px 10px;
  margin-top: 23px;
}

.timer-timer {
  /* background-color: #747474; */
  border: 1px solid #000000;
  font-size: 50px;
  margin: 23px;
  padding: 10px 10px 10px 10px;
}

.start-stop-timer {
  margin: 10px;
}

/* .break-timer {
  position: absolute;
  right: 0;
  left: 0;
  background-color: transparent;
} */

h1,
h2 {
  margin: 0;
}

.fa-timer {
  font-size: x-large;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}

@media screen and (max-width: 650px) {
  .stopwatch-timer {
    transform: scale(0.8);
    margin-top: 0px;
  }
}
@media screen and (max-width: 515px) {
  .stopwatch-timer {
    transform: scale(0.6);
    margin-top: -80px;
    margin-bottom: -80px;
  }
}
@media screen and (max-width: 400px) {
  .stopwatch-timer {
    transform: scale(0.45);
    margin-top: -100px;
    margin-bottom: -100px;
  }
}
