.App-randomQuoteMachine {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  font-family: "Raleway", sans-serif;
  min-height: 100vh;
}

/* Main Display */
.header-randomQuoteMachine {
  width: 65%;
  min-width: 250px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 7px 7px rgba(0, 0, 0, 0.1);
  animation: header-fadeIn 3s;
  margin-top: 18vh;
}

@keyframes header-fadeIn {
  0% {
    opacity: 0.01;
  }
  100% {
    opacity: 1;
  }
}

.quote-randomQuoteMachine {
  font-size: 2rem;
}

.author-randomQuoteMachine {
  font-size: 1.5rem;
}

.button-container-randomQuoteMachine {
  width: 100%;
  display: flex;
  margin-top: 1rem;
}

.blank-button-start-randomQuoteMachine {
  flex: 4;
}

.new-quote-button-randomQuoteMachine {
  font-family: "Raleway", sans-serif;
  padding: 12px 17px;
  background-color: #fff;
  border: none;
  box-shadow: 0.5px 0.5px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-size: 1rem;
  flex: 2;
  min-width: 150px;
  cursor: pointer;
}

.new-quote-button-randomQuoteMachine:active {
  box-shadow: 0.5px 0.5px 0px 0px rgba(0, 0, 0, 0.25);
}

.blank-button-end-randomQuoteMachine {
  flex: 3;
}

.fa-randomQuoteMachine {
  font-size: 2.75rem;
  flex: 1;
  cursor: grab;
}

/* Loading Animation */
.lds-ripple-randomQuoteMachine {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple-randomQuoteMachine div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple-randomQuoteMachine div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple-randomQuoteMachine {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
