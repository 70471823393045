.fade-in-main {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1;
  background-color: black;
  animation-name: fadeInMain;
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

@keyframes fadeInMain {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Define the keyframes for the fadeIn animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Apply the fadeIn animation to elements with the .fade-in class */
.fade-in {
  animation: fadeIn 2s ease-in-out;
}
html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}
