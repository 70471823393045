.App-drum {
  text-align: center;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.controller-drum {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 8px solid #4eacff;
  border-radius: 20px;
  height: 50vh;
  width: 50vw;
  margin-top: 15vh;
}

.button-grid-container-drum {
  flex: 1;
  display: grid;
  grid-template-columns: auto auto auto;
  width: 30vw;
  height: 40vh;
  column-gap: 1.3vw;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  padding-left: 2vw;
}

.button-grid-item-drum {
  background-color: #4eacff;
  box-shadow: 3px 3px rgba(0, 0, 0, 0.5);
  width: 8.5vw;
  height: 10vh;
}

.button-grid-item-drum:active {
  background-color: aqua;
  box-shadow: none;
}

.controls-container-drum {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: 33% 33% 33%;
  align-items: center;
  height: 40vh;
  flex: 1;
}

.block2-drum {
  grid-column: 2;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3vh;
}

.power-button-drum {
  height: 4vh;
  width: 8.5vw;

  background-color: #4eacff;
  border-radius: 5px;
  box-shadow: 3px 3px rgba(0, 0, 0, 0.5);
  margin-bottom: -2vh;
}

.power-button-drum:active {
  box-shadow: none;
  background-color: aqua;
}

.block3-drum {
  grid-column: 3;
  grid-row: 1;
  display: flex;
  justify-content: center;
}

.power-light-drum {
  height: 30px;
  width: 30px;
  border-radius: 100px;
  background-color: greenyellow;
  margin-left: 1vw;
  margin-bottom: 15vh;
  box-shadow: 2px 2px 1px 1px rgba(0, 0, 0, 0.5);
}

.block5-drum {
  grid-column: 1 / span 3;
  grid-row: 2;
  display: flex;
  justify-content: center;
}

.block5-drum > h4 {
  background-color: #bfd5e7;
  width: 80%;
  height: 5vh;
  padding-top: 1vh;
  text-align: center;
}

.block8-drum {
  grid-column: 1 / span 3;
  grid-row: 3;
}

.block8-drum > h4 {
  margin-top: 0;
}

footer {
  color: #ffffff;
  position: absolute;
  bottom: 0;
  margin-bottom: 2vh;
}

@media screen and (max-width: 700px) {
  .controller-drum {
    flex-direction: column;
    height: fit-content;
    padding-top: 2vh;
  }

  .button-grid-container-drum {
    margin-right: 2vw;
  }

  .button-grid-item-drum {
    width: 12vw;
    margin: 5px;
  }

  .controls-container-drum {
    width: 50vw;
    height: 40vh;
    margin-top: 2vh;
    flex: 1;
  }

  .power-button-drum {
    width: 12vw;
  }

  .power-light-drum {
    justify-self: center;
    margin: 0;
  }
}

@media screen and (max-width: 340px) {
  .controller-drum {
    transform: scale(0.6);
    width: 90vw;
  }

  .button-grid-container-drum {
    width: 85vw;
  }

  .button-grid-item-drum {
    width: 20vw;
  }

  .block5-drum > h4 {
    font-size: x-small;
  }

  .controls-container-drum {
    width: 50vw;
    height: 40vh;
    margin-top: 2vh;
    flex: 1;
  }

  .power-button-drum {
    width: 20vw;
  }

  .power-light-drum {
    justify-self: center;
    margin-left: 10vw;
  }

  .block8-drum > input {
    width: 50vw;
  }
}
